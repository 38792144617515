import {useEffect,useState} from 'react'
function useFetch(url,lang,isAuthenticated = false){
const [data,setData] = useState(null);
useEffect(() => {
    let language = localStorage.getItem('language')
    var headers = {}
    if(isAuthenticated){
        let authCode = localStorage.getItem('RefreshTokenResponse')
        let urn = localStorage.getItem('Parentuuid')
        headers = {
            urn: urn,
            authCode: authCode
        }
    }
    
    if(language){
        lang=language
        url = url.substr(0,url.length-2)+lang
    }

    

    fetch(url,{
        method : 'GET',
        mode: 'cors',
        headers: headers
    }).then((response) => {
        return response.json()
    }).then((data) => {
        setData(data)
    }).catch((error) => {
        console.log(error)
    })
}, [lang])
return [data]
}

export default useFetch